<template>
  <div ref="report" class="report-edit">
    <div class="header">
      <div class="header-title">
        {{ title }}
      </div>
      <en-icon
        v-if="edit"
        name="shanchu-danchuang"
        size="12px"
        @click.native="deleteData"
      >
      </en-icon>
    </div>
    <div class="content" ref="myChart" :style="'width:' + width + 'px;' + 'height:' + height + 'px;' ">
    </div>
  </div>
</template>

<script>

import echarts from "echarts";

export default {
  name: "report-edit",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  data() {
    return {
      chart: null,
      width: 300,
      height: 400
    };
  },
  computed: {
    title() {
      return this.menuData?.name;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const self = this;
      setTimeout(() => {
        self.width = self.$el.clientWidth;
        self.height = self.$el.clientHeight - 44;
        self.drawLine();
        const myObserver = new ResizeObserver((entries) => {
          const el = entries[0];
          self.width = el.contentRect.width;
          self.height = el.contentRect.height - 44;
          self.chart.resize();
        });
        myObserver.observe(self.$refs.report);
      }, 300);
    });
  },
  methods: {
    deleteData() {
      this.$emit("deleteData");
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.myChart);
      this.chart = myChart;
      // 绘制图表
      myChart.setOption({
        tooltip: {
          showDelay: 0
        },
        xAxis: {
          data: ["行政", "综合", "采购", "技术", "人事"],
          axisLabel: {
            textStyle: {
              color: "#636C78"
            }
          },
          splitLine: {
            lineStyle: {
              color: "#E8ECF2"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#E8ECF2"
            }
          }
        },
        yAxis: [{
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#636C78"
            }
          },
          splitLine: {
            lineStyle: {
              color: "#E8ECF2"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#E8ECF2"
            }
          },
          max: 2000
        }],
        series: [{
          name: "销量",
          type: "bar",
          barWidth: 20,
          data: [800, 1800, 1500, 1300, 1700],
          itemStyle: {
            normal: {
              barBorderRadius: [4, 4, 0, 0],
              color: "#26C393",
              lineColor: "#E8ECF2"
            }
          }
        }],
        grid: {
          left: "5%",
          right: "5%",
          top: "10%",
          bottom: "10%",
          containLabel: true
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.report-edit {
  height: 100%;
  .header {
    width: 100%;
    line-height: 44px;
    border-bottom: 1px solid #e8ecf2;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;

    .header-title {
      color: #1A1C1E;
      font-size:14px;
      font-weight: bold;
    }
    .en-icon {
      cursor: pointer;
      color: #a9b5c6;
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
}

</style>
