var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "report", staticClass: "report-edit" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("div", { staticClass: "header-title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _vm.edit
          ? _c("en-icon", {
              attrs: { name: "shanchu-danchuang", size: "12px" },
              nativeOn: {
                click: function ($event) {
                  return _vm.deleteData.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("div", {
      ref: "myChart",
      staticClass: "content",
      style: "width:" + _vm.width + "px;" + "height:" + _vm.height + "px;",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }